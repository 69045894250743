import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";

import { createHead } from "@vueuse/head";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "./fontawesome";

import {
  faBook,
  faCheckCircle,
  faChevronDown,
  faEye,
  faMoneyBill,
  faPen,
  faPlus,
  faPowerOff,
  faTrash,
  faUser,
  faGear,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBook,
  faChevronDown,
  faMoneyBill,
  faPowerOff,
  faPen,
  faEye,
  faTrash,
  faPlus,
  faUser,
  faCheckCircle,
  faGear,
  faCopy
);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdI65s8N4CAfAXClqVlo1na01cFkQ8FdM",
  authDomain: "multihoplora.firebaseapp.com",
  projectId: "multihoplora",
  storageBucket: "multihoplora.appspot.com",
  messagingSenderId: "48131143800",
  appId: "1:48131143800:web:beabdfc8d29384b605d58d",
  measurementId: "G-1K1YXTELLK",
};

// Initialize Firebase
const config = initializeApp(firebaseConfig);
getAnalytics(config);
getDatabase(config);

const app = createApp(App);
const head = createHead();

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(head);

app.mount("#app");
