<template>
  <LayoutAdmin>
    <div class="w-full bg-gray-50 py-6 rounded-lg justify-between md:flex">
      <h1 class="text-4xl font-medium text-[#073545] px-10">History</h1>
      <div class="right-0 px-10 mt-3">
        <BreadCrumbComp :dataBreadCrumb="breadcrumbData" />
      </div>
    </div>

    <div class="mt-10 bg-gray-50 p-6 rounded-lg">
      <div class="md:flex mt-4 mb-6">
        <div class="md:grow md:mr-2">
          <button
            class="mt-0 items-center p-2 text-white bg-[#47A101] rounded-l-lg"
            data-modal-target="authentication-modal"
            data-modal-toggle="authentication-modal"
            type="button"
          >
            <span class="ml-2 text-sm"> Periode </span>
          </button>

          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            class="mt-0 items-center p-2 text-white bg-yellow-400 rounded-r-lg"
            type="button"
          >
            <font-awesome-icon icon="fa-solid fa-chevron-down" />
          </button>

          <!-- Dropdown menu -->
          <div
            id="dropdown"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              class="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li class="m-2">
                <button
                  type="button"
                  class="w-full font-medium hover:bg-yellow-400 rounded p-1.5 text-yellow-400 hover:text-white outline outline-yellow-400 ease-in-out duration-200"
                >
                  Day
                </button>
              </li>
              <li class="m-2">
                <button
                  type="button"
                  class="w-full font-medium hover:bg-yellow-400 rounded p-1.5 text-yellow-400 hover:text-white outline outline-yellow-400 ease-in-out duration-200"
                >
                  Weeks
                </button>
              </li>
              <li class="m-2">
                <button
                  type="button"
                  class="w-full font-medium hover:bg-yellow-400 rounded p-1.5 text-yellow-400 hover:text-white outline outline-yellow-400 ease-in-out duration-200"
                >
                  Months
                </button>
              </li>
              <li class="m-2">
                <button
                  type="button"
                  class="w-full font-medium hover:bg-yellow-400 rounded p-1.5 text-yellow-400 hover:text-white outline outline-yellow-400 ease-in-out duration-200"
                >
                  Years
                </button>
              </li>
              <!-- <li class="m-2">
                <button
                  type="button"
                  class="w-full font-medium hover:bg-red-700 rounded p-1.5 text-red-700 hover:text-white outline outline-red-700 ease-in-out duration-200"
                >
                  <font-awesome-icon icon="fa-solid fa-trash" />
                  Delete
                </button>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="md:mt-0 mt-5">
          <input
            type="search"
            id="default-search"
            class="w-full md:w-80 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-sky-900 focus:border-sky-900"
            placeholder="Search for notulensi"
            @change="search"
            required
          />
        </div>
      </div>

      <div class="relative overflow-x-auto sm:rounded-lg">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead class="text-xs text-gray-500 uppercase bg-gray-300">
            <tr class="text-center">
              <!-- <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    @change="selectAll"
                    value="all"
                    class="w-4 h-4 text-sky-900 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label for="checkbox-all-search" class="sr-only"
                    >checkbox</label
                  >
                </div>
              </th> -->
              <th scope="col" class="px-6 py-3">Timestamp</th>
              <th scope="col" class="px-6 py-3">Temperature</th>
              <th scope="col" class="px-6 py-3">Humidity</th>
              <th scope="col" class="px-6 py-3">pH</th>
              <th scope="col" class="px-6 py-3">Nitrogen</th>
              <th scope="col" class="px-6 py-3">Fosfor</th>
              <th scope="col" class="px-6 py-3">Kalium</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b dark:bg-gray-800 text-center dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <!-- <td class="w-4 p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-table-search"
                    type="checkbox"
                    class="w-4 h-4 text-sky-900 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label for="checkbox-table-search" class="sr-only"
                    >index</label
                  >
                </div>
              </td> -->
              <td class="w-4 p-4">00:01:00</td>
              <td class="w-4 p-4">31.27 °C</td>
              <td class="w-4 p-4">55.20 %</td>
              <td class="w-4 p-4">5.0</td>
              <td class="w-4 p-4">0.23</td>
              <td class="w-4 p-4">25</td>
              <td class="w-4 p-4">150</td>
            </tr>
            <!-- <tr
              v-if="shortlinks == null || shortlinks.length === 0"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td class="text-center col-span-3">Tidak ada</td>
            </tr>
            <tr
              v-for="(shrtlnk, index) in shortlinks"
              :key="index"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input
                    :id="'checkbox-table-search-' + index"
                    type="checkbox"
                    :value="index"
                    :checked="isChecked(index)"
                    @change="selectData"
                    class="w-4 h-4 text-sky-900 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    :for="'checkbox-table-search-' + index"
                    class="sr-only"
                    >{{ shrtlnk.id }}</label
                  >
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span class="font-bold uppercase">{{ shrtlnk.title }}</span>
                <p class="text-sm">{{ shrtlnk.place }}</p>
                <p class="text-sm mb-2">{{ dateTime(shrtlnk.created_at) }}</p>
                <a
                  class="text-sm text-yellow-400 hover:outline hover:outline-yellow-400 ease-in-out duration-150 p-1 rounded"
                  :href="shrtlnk.url_gdocs"
                  target="_blank"
                >
                  <font-awesome-icon icon="fa-solid fa-eye" />
                  Click here</a
                >
              </td>

              <td class="px-6 py-4">
                <span
                  class="block uppercase font-semibold text-yellow-400 outline outline-1 outline-yellow-400 rounded-lg text-center py-1 hover:bg-yellow-400 hover:text-white ease-in-out duration-300"
                >
                  {{ shrtlnk.category }}
                </span>
              </td>

              <td class="px-6 py-4">
                {{ shrtlnk.user_email }}
                <p v-if="shrtlnk.updated_by">
                  Update by : {{ shrtlnk.updated_by }}
                </p>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </LayoutAdmin>
</template>

<script>
import BreadCrumbComp from "@/components/admin/BreadCrumbAdmin.vue";
import LayoutAdmin from "@/layouts/LayoutAdmin.vue";

import { useHead } from "@vueuse/head";
export default {
  name: "HistoryAdmin",
  components: {
    BreadCrumbComp,
    LayoutAdmin,
  },
  setup() {
    useHead({
      title: "History | SMARTNET",
    });
  },
  data() {
    return {
      breadcrumbData: [{ url: "/dashboard/history", name: "History" }],
    };
  },
};
</script>

<style>
</style>